$(function() {
    $('.top').on('click', function(event){
        $('html, body').animate({scrollTop: 0},500);
        return false;
    });

    $('.js-toggler').on('click', function(event){
        $(this).toggleClass('open').prev().toggleClass('open');
        $('body').toggleClass('overfl');
    });

    $('.js-contacts-btn').on('click', function(event){
        $(this).parent().parent().parent().toggleClass('open');
    });


    $('li.drop .arrow').on('click', function(event){
        $(this).parent().toggleClass('op');
    });

    $(".js-example-responsive").select2();

    $('select').on('select2:open', function (e) {
        $('.select2-results__options').mCustomScrollbar('destroy');
        setTimeout(function () {
            $('.select2-results__options').mCustomScrollbar();
        }, 0);
    });



    $('.js-foto-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    variableWidth: false
                }
            }
        ]
    })

    $('.js-clinics-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            }
        ]
    })

    $('.js-reviews-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '100px',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    variableWidth: false,
                    centerMode: false,
                    centerPadding: '0'
                }
            }
        ]
    })
    $('.js-reviews-slider2').slick({
        dots: false,
        infinite: true,
        slidesToShow: 5,
        centerMode: true,
        centerPadding: '100px',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    variableWidth: false,
                    centerMode: false,
                    centerPadding: '0'
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    variableWidth: false,
                    centerMode: false,
                    centerPadding: '0'
                }
            }
        ]
    })
    $('.js-operations-slider').slick({
        dots: false,
        infinite: true,
        slidesToShow: 4,
        arrows: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    })

    $(window).on('load resize', function () {
        if ($(window).width() < 991) {
            $('.subscription__list').slick({
                slidesToScroll: 1,
                variableWidth: true,
                arrows: true,
                dots: true
            })
            $('.subscription__list').show()
        }
    })




})
